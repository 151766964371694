import { css } from '@emotion/react'
import { baseColors, mobileWidth } from 'theme'
const styles = {
  wrapper: css`
    background-color: ${baseColors.cardBg};
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(95vw - 48px);
    border-radius: 16px;

    max-height: 90vh;
    overflow: auto;

    @media only screen and (min-width: ${mobileWidth}px) {
      width: 500px;
    }
  `,

  bgWrapper: css`
    width: calc(95vw - 48px);
    height: calc((95vw - 48px) * 0.6615);

    @media only screen and (min-width: ${mobileWidth}px) {
      width: 500px;
      height: calc(500px * 0.6615);
    }
  `,
  bgWrapperContent: css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  `,
  shoeImg: css`
    width: 50%;
    margin-bottom: -30px;
  `,
  shoeId: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
  `,

  modalTitle: css`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${baseColors.neutralColor1};
  `,
  shoeInfoWrapper: css`
    width: 90%;
  `,

  detailWrap: css({
    margin: 24,
    marginTop: 0,
  }),
  infoWrap: css({
    marginTop: 24,
  }),

  actions: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 12px);
    max-width: 400px;
    margin: 0 auto;
  `,
  cancelBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  confirmBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  infoRow: css`
    margin-top: 16px;
    > span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
  `,
  confirmInfoItem: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    span:first-of-type {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
    span:last-of-type {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
  `,
}

export default styles
