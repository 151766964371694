/** @jsxImportSource @emotion/react */
import { Modal, Box, Button, CircularProgress, Slider } from '@mui/material'
import _toLower from 'lodash/toLower'
import _get from 'lodash/get'
import { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import Images from 'images'
import WrapperBgImg from 'components/wrap-background-img/WrapperBgImg'
import { baseColors, commonClass } from 'theme'
import { capitalizeFirstLetter } from 'helpers/utils'
import ShoeInfo from 'components/shoe-info/ShoeInfo'
import { useAppDispatch } from 'store'
import { showToast } from 'store/reducers/common'
import { IShoe } from 'types/nft'
import { getRepairShoeCost, repairShoe } from 'store/reducers/bag'
import { fetchWalletBallance } from 'store/reducers/wallet'
import styles from './RepairModal.styles'
interface IProps {
  isOpen: boolean
  handleClose: () => void
  shoeDetail: IShoe | null
  onSuccess: () => void
}

const RepairModal = ({
  isOpen,
  handleClose,
  shoeDetail,
  onSuccess,
}: IProps) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const durable = shoeDetail?.durable || 0
  const [dura, setDura] = useState(durable)
  const [costFit, setCostFit] = useState(0)
  const shoeId = shoeDetail?.shoeId

  useEffect(() => {
    setDura(durable)
  }, [durable, isOpen])

  useEffect(() => {
    setCostFit(0)
    if (isOpen && shoeId) {
      dispatch(
        getRepairShoeCost({
          shoeId: shoeId,
        }),
      )
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            setCostFit(response.fitCost)
          }
        })
    }
  }, [isOpen, shoeId])

  useEffect(() => {
    if (!isOpen) {
      setLoading(false)
    }
  }, [isOpen])

  const handleConfirm = () => {
    setLoading(true)
    if (shoeId) {
      dispatch(
        repairShoe({
          durable: dura - durable,
          shoeId: shoeId,
        }),
      )
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            dispatch(fetchWalletBallance({}))
            onSuccess()
            handleClose()
          } else {
            dispatch(
              showToast({
                title: 'Error',
                message: response.message,
              }),
            )
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const onChangeDurable = (newVal: number) => {
    if (newVal >= durable) {
      setDura(newVal)
    }
  }

  const renderShoeDetail = () => {
    const qualityText = capitalizeFirstLetter(shoeDetail?.quality)
    const qualityColor = _get(baseColors, qualityText + 'Color')
    const qualityBg = (Images.qualityBg as any)[
      _toLower(shoeDetail?.quality) || 'common'
    ] as string

    return (
      <>
        <div css={styles.bgWrapper}>
          <WrapperBgImg src={qualityBg}>
            <div css={styles.bgWrapperContent}>
              <img css={styles.shoeImg} src={shoeDetail?.image} alt='' />

              <div
                className='mb-2'
                css={styles.shoeId}
                style={{ color: qualityColor }}
              >
                #{shoeDetail?.shoeId}
              </div>

              <div className='mb-3 mx-auto' css={styles.shoeInfoWrapper}>
                <ShoeInfo
                  className='mx-auto'
                  level={shoeDetail?.level}
                  shoeClass={shoeDetail?.class}
                  durable={durable}
                  color={qualityColor}
                />
              </div>
            </div>
          </WrapperBgImg>
        </div>
      </>
    )
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box css={styles.wrapper}>
        <>
          <div className='df jcb'>
            <div css={styles.modalTitle}>Repair</div>
            <div className='pointer' onClick={handleClose}>
              <Images.CloseIcon />
            </div>
          </div>

          {renderShoeDetail()}

          <div>
            <div css={styles.duraText}>Durability</div>
            <div>
              <Slider
                css={styles.repairCostSlide}
                value={dura}
                valueLabelDisplay='on'
                max={100}
                min={0}
                onChange={(e, value) => onChangeDurable(value as number)}
              />
            </div>

            <div css={styles.costInfoRow}>
              <span>Cost</span>

              <span>{((dura - durable) * costFit).toFixed(2)} FIT</span>
            </div>
          </div>

          <div css={styles.actions} className='mt-5 mx-auto'>
            <Button
              css={[commonClass.outlineButton, styles.cancelBtn]}
              onClick={handleClose}
            >
              CANCEL
            </Button>
            <Button
              onClick={handleConfirm}
              css={[commonClass.appButton, styles.confirmBtn]}
              disabled={loading || durable === dura || costFit === 0}
            >
              {!!loading && <CircularProgress size={18} />}
              CONFIRM
            </Button>
          </div>
        </>
      </Box>
    </Modal>
  )
}

export default RepairModal
