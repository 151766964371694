import BaseAxios, { AxiosInstance } from 'axios'
import get from 'lodash/get'
import { store } from 'store'
import { generateSignature } from './generateSignature'
import { getDeviceId } from './utils'
const HIDE_MESSAGE_ERROR_CODES = [404, 500, 9999]

export type IRequestResponse<ResponseObj> = {
  apiStatus: 0 | 1
  errorStatus?: any
  message?: any
} & ResponseObj extends infer U
  ? { [K in keyof U]: U[K] }
  : never

class RequestClass {
  axios: AxiosInstance
  constructor() {
    this.axios = BaseAxios.create({ timeout: 60000 })
  }
  async call<ResponseObj>(config: {
    url: string
    method: string
    data?: any
    serverBaseUrl?: string
    headers?: any
  }) {
    try {
      const serverBaseUrl = process.env.REACT_APP_BASE_API
      const state = store.getState()
      const token = get(state, 'auth.token')
      // TODO: get from firebase and store in storage
      const sharedSecret = get(state, 'auth.firebaseConfig.sharedSecret')

      const deviceId = getDeviceId()

      if (!config.headers) {
        config = {
          ...config,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-source': 'CALO',
          },
        }
      }
      if (token) {
        config = {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
            'x-source': 'CALO',
          },
        }
        if (config?.method === 'GET') {
          config.data = undefined
        }
      }

      config.data = {
        ...config.data,
        deviceId,
      }

      // Generate checksum
      if (config?.serverBaseUrl) {
        config.headers['x-request-checksum'] = generateSignature({
          url: '/v2' + config.url,
          body: config.data,
          sharedSecret,
          method: config.method,
        })
      }
      const res = await this.axios.request({
        baseURL: config?.serverBaseUrl || serverBaseUrl,
        ...config,
      })

      return { ...res.data, apiStatus: 1 } as IRequestResponse<ResponseObj>
    } catch (error) {
      const errorStatus = get(error, 'response.status', null)
      const data = get(error, 'response.data', {}) || {}

      if (
        HIDE_MESSAGE_ERROR_CODES.includes(errorStatus) ||
        HIDE_MESSAGE_ERROR_CODES.includes(data?.code) ||
        !data.message
      ) {
        data.message = 'Oops, Something Went Wrong'
      }
      if (get(error, 'message') === 'Network Error') {
        data.message = 'Network Error'
        data.code = 444
      }

      return {
        ...data,
        apiStatus: 0,
        errorStatus,
      } as unknown as IRequestResponse<ResponseObj>
    }
  }
}

const Request = new RequestClass()

export { Request }
