import { createSlice } from '@reduxjs/toolkit'
import {
  getWalletBalanceApi,
  getWithdrawFeeApi,
  getWithdrawOTPApi,
} from 'api/wallet'
import { createCallApiAsyncAction } from 'store/middlewares/createAsyncAction'
export const TYPE = 'WALLET'

export const fetchWalletBallance = createCallApiAsyncAction(
  TYPE + '/fetchWalletBallance',
  getWalletBalanceApi,
)

export const getWithdrawFee = createCallApiAsyncAction(
  TYPE + '/getWithdrawFee',
  getWithdrawFeeApi,
)

export const getWithdrawOTP = createCallApiAsyncAction(
  TYPE + '/getWithdrawOtp',
  getWithdrawOTPApi,
)

interface WalletState {
  fit: number
  calo: number
}

const initialState: WalletState = {
  fit: 0,
  calo: 0,
}

const walletSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWalletBallance.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.fit = response.fitBalance || 0
        state.calo = response.caloBalance || 0
      } else {
        state.fit = 0
        state.calo = 0
      }
    })
  },
})

export default walletSlice.reducer
