/** @jsxImportSource @emotion/react */
import { Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import SelectShoeModal from 'components/select-shoe/SelectShoeModal'
import { useAppDispatch, useAppSelector } from 'store'
import {
  fetchBagShoeItem,
  fetchCostMint,
  fetchMintShoe,
} from 'store/reducers/bag'
import { ICostMint, IShoe, IShoeBox } from 'types/nft'
import Images from 'images'
import { commonClass } from 'theme'
import { showToast } from 'store/reducers/common'
import MintResult from 'components/mint-result/MintResult'
import ShoeboxOpen from 'components/shoebox-open/ShoeboxOpen'
import { fetchWalletBallance } from 'store/reducers/wallet'
import styles from './Mint.styles'
import mainStyles from '../market/ShoeDetailMarket.styles'

const MintPage = () => {
  const [isOpenSelect, setOpenSelect] = useState(false)
  const [rightShoe, setRightShoe] = useState<IShoe | null>(null)
  const [result, setResult] = useState<IShoeBox | null>(null)

  const [shoeboxOpen, setShoeboxOpen] = useState<IShoeBox | null>(null)
  const dispatch = useAppDispatch()
  const shoeDetail = useAppSelector((state) => state.bag.shoeDetail)
  const { shoeId } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [cost, setCost] = useState<ICostMint>({ fitCost: 0, caloCost: 0 })

  useEffect(() => {
    handleRefreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoeId])

  const handleRefreshData = () => {
    if (shoeId) {
      dispatch(
        fetchBagShoeItem({
          shoeId: +shoeId,
        }),
      )
    }
  }

  useEffect(() => {
    if (!rightShoe || !shoeDetail) {
      return
    }
    dispatch(
      fetchCostMint({
        leftShoeId: shoeDetail?.shoeId,
        rightShoeId: rightShoe.shoeId,
      }),
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setCost({
          caloCost: response.caloCost * 1,
          fitCost: response.fitCost * 1,
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightShoe?.shoeId, shoeDetail?.shoeId])
  const runMint = () => {
    if (!rightShoe || !shoeDetail) {
      return
    }
    setLoading(true)

    dispatch(
      fetchMintShoe({
        leftShoeId: shoeDetail.shoeId,
        rightShoeId: rightShoe.shoeId,
      }),
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.shoeBoxId) {
          setResult(response)
          dispatch(fetchWalletBallance({}))
        } else {
          dispatch(
            showToast({
              title: 'Error',
              message: response.message,
            }),
          )
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (!shoeDetail || !shoeDetail.shoeId) {
    return <div />
  }

  return (
    <div css={mainStyles.wrapper}>
      <div css={styles.wrapper}>
        <div css={styles.imgBackground}>
          <div css={styles.logo}>
            <img src={Images.Logo} alt='Add-shoe' />
          </div>
          <div css={styles.wrapShoe}>
            <div css={styles.leftShoe}>
              <img src={shoeDetail?.image} alt='Left-shoe' />
              <div css={styles.shoeInfo}>
                <div>Lv {shoeDetail.level}</div>
                <div>{shoeDetail.mint}/7</div>
              </div>
            </div>

            {rightShoe ? (
              <div css={styles.rightShoe} onClick={() => setOpenSelect(true)}>
                <img src={rightShoe?.image} alt='Right-shoe' />
                <div css={styles.shoeInfo}>
                  <div>Lv {rightShoe.level}</div>
                  <div>{rightShoe.mint}/7</div>
                </div>
              </div>
            ) : (
              <div
                css={styles.rightShoeAdd}
                onClick={() => setOpenSelect(true)}
              >
                <img src={Images.AddShoe} alt='Add-shoe' />
              </div>
            )}
          </div>
        </div>
        <div css={styles.priceInfo}>
          <p>Token consumption</p>
          <div>
            <p css={styles.price}>{cost.caloCost} CALO</p>
            <p css={styles.price}>{cost.fitCost} FIT</p>
          </div>
        </div>
        <div css={styles.actions}>
          <Button
            disabled={!rightShoe || loading}
            css={[commonClass.appButton, styles.buttonMint]}
            onClick={() => runMint()}
          >
            Mint
          </Button>
        </div>
      </div>
      <SelectShoeModal
        isOpen={isOpenSelect}
        handleClose={() => setOpenSelect(false)}
        onSuccess={(e: IShoe) => {
          setRightShoe(e)
          setOpenSelect(false)
        }}
        filters={[
          {
            field: 'level',
            operator: '$gte',
            value: 5,
          },
          {
            field: 'mint',
            operator: '$lt',
            value: 7,
          },
          {
            field: 'shoeId',
            operator: '$ne',
            value: shoeDetail?.shoeId,
          },
          {
            field: 'status',
            operator: '$eq',
            value: 'Active',
          },
          {
            field: 'cooldownTime',
            operator: '$isnull',
          },
        ]}
      />

      <MintResult
        isOpen={!!result}
        itemDetail={result}
        handleClose={() => navigate('/bag')}
        onOpen={() => {
          setShoeboxOpen(result)
          setResult(null)
        }}
      />

      <ShoeboxOpen
        isOpen={!!shoeboxOpen}
        handleClose={() => setShoeboxOpen(null)}
        itemDetail={shoeboxOpen}
        onOpenSuccess={() => {
          setShoeboxOpen(null)
          navigate('/bag')
        }}
      />
    </div>
  )
}

export default MintPage
