import { Request } from 'helpers/request'
import {
  IShoeBoxListResponse,
  IShoeListRequest,
  IShoeListResponse,
  IStoneListRequest,
} from 'types/bag'
import { ICostMint, IMana, IShoe, IShoeBox, IStone } from 'types/nft'
// import { shoeboxMock } from './mock'

export const getShoeListApi = (payload: IShoeListRequest) => {
  return Request.call<IShoeListResponse>({
    url: '/user.nft.list',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const getShoeDetailApi = (payload: { shoeId: number }) => {
  return Request.call<IShoe>({
    url: '/nft.shoe.detail',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const getShoeBoxDetailApi = (payload: { shoeBoxId: number }) => {
  return Request.call<IShoeBox>({
    url: '/nft.shoebox.detail',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const getShoeBoxListApi = (payload: IShoeListRequest) => {
  return Request.call<IShoeBoxListResponse>({
    url: '/user.nft.list',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const getManaListApi = (payload: IStoneListRequest) => {
  return Request.call<{
    data: IMana[]
  }>({
    url: '/mana.list',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const getStoneListApi = (payload: IStoneListRequest) => {
  return Request.call<{
    data: IStone[]
  }>({
    url: '/nft.stone.list',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const openShoeBoxApi = (payload: { shoeBoxId: number }) => {
  // return Promise.resolve({
  //   openedShoeId: 280545712,
  //   apiStatus: 1,
  //   message: '',
  // } as any)

  return Request.call<IShoeBox>({
    url: '/nft.shoebox.open',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const upgradeShoeApi = (payload: { shoeId: number }) => {
  return Request.call<IShoe>({
    url: '/nft.shoe.upgrade',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const boostShoeTimeApi = (payload: { shoeId: number }) => {
  return Request.call<IShoe>({
    url: '/nft.shoe.boost_time',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const mintShoeApi = (payload: {
  leftShoeId: number
  rightShoeId: number
}) => {
  // return Promise.resolve(shoeboxMock() as any)

  return Request.call<IShoeBox>({
    url: '/nft.shoe.mint',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const costMintShoeApi = (payload: {
  leftShoeId: number
  rightShoeId: number
}) => {
  return Request.call<ICostMint>({
    url: '/nft.shoe.mint.cost',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}
export const addShoePointApi = (payload: {
  shoeId: number
  performance?: number
  luck?: number
  speed?: number
  recovery?: number
}) => {
  return Request.call<IShoe>({
    url: '/nft.shoe.add_point',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const repairShoeApi = (payload: { shoeId: number; durable: number }) => {
  return Request.call<IShoe>({
    url: '/nft.shoe.repair',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const getRepairShoeCostApi = (payload: { shoeId: number }) => {
  return Request.call<{ fitCost: number }>({
    url: '/nft.shoe.repair.cost',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}
