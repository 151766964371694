import { css } from '@emotion/react'
import { baseColors, mobileWidth } from 'theme'
const styles = {
  wrapper: css`
    background-color: ${baseColors.cardBg};
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(95vw - 48px);
    border-radius: 16px;

    max-height: 90vh;
    overflow: auto;
    @media only screen and (min-width: ${mobileWidth}px) {
      width: 500px;
    }
  `,

  mess: css`
    text-align: center;
  `,

  bgWrapper: css`
    width: calc(95vw - 48px);
    height: calc((95vw - 48px) * 0.6615);

    @media only screen and (min-width: ${mobileWidth}px) {
      width: 500px;
      height: calc(500px * 0.6615);
    }
  `,
  bgWrapperContent: css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  shoeImg: css`
    width: 50%;
  `,
  shoeId: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
  `,

  modalTitle: css`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${baseColors.neutralColor1};
  `,

  wrapperImg: css`
    height: 600px;
  `,
  mintWrapper: css`
    width: calc(100% - 12px);
    max-width: 400px;
  `,
  mintText: css`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${baseColors.secondaryColor2};
    margin-bottom: 2px;
  `,

  priceWrapper: css`
    width: calc(100% - 12px);
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${baseColors.neutralColor1};

    img {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  `,

  actions: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 12px);
    max-width: 400px;
    gap: 15px;
  `,
  action: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 12px);
    max-width: 400px;
  `,

  shoeInfoWrapper: css`
    width: 90%;
  `,
  priceInputWrapper: css`
    margin-top: 16px;
  `,
  priceLabel: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  `,
  priceInput: css`
    width: 100%;
    height: 52px;
    .MuiInputBase-root {
      background: ${baseColors.appBg};
      border-radius: 12px;
      margin-top: 12px;
    }
    * {
      color: ${baseColors.neutralColor1} !important;
      border: 0 !important;
      outline: none !important;
      box-shadow: none !important;
    }
  `,

  confirmInfo: css`
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  `,
  confirmInfoItem: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    background: ${baseColors.appBg};
    padding: 16px;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  `,
  animationWrap: css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    .svg-wrapper {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    .shoe-img {
      position: fixed;
      width: 40%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      opacity: 1;
      &.shoe-left {
        left: 20%;
        animation: horizontal-shaking-left 3s forwards;
      }
      &.shoe-right {
        left: 80%;
        transform: translate(-50%, -50%) scaleX(-1);
        animation: horizontal-shaking-right 3s forwards;
      }
    }
    .box-img {
      animation: fadeIn 2s forwards;
      animation-delay: 3s;
      position: fixed;
      width: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.5);
      opacity: 0;
      z-index: 2;
    }
    @media only screen and (min-width: ${mobileWidth}px) {
      .svg-wrapper {
        width: 80%;
        height: 80%;
      }
      .box-img {
        width: 30%;
      }
      .shoe-img {
        width: 25%;
        &.shoe-left {
          left: 30%;
        }
        &.shoe-right {
          left: 70%;
        }
      }
    }
    @keyframes fadeIn {
      0% {
        transform: translate(-50%, -50%) scale(0.1);
        opacity: 0;
      }
      100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
    }
    @keyframes horizontal-shaking-left {
      0% {
        transform: translate(-50%, -50%);
        opacity: 1;
      }
      33% {
        transform: translate(-20%, -50%);
        opacity: 1;
      }
      66% {
        transform: translate(-50%, -50%);
        opacity: 1;
      }
      100% {
        transform: translate(-20%, -50%);
        opacity: 0;
      }
    }
    @keyframes horizontal-shaking-right {
      0% {
        transform: translate(-50%, -50%) scaleX(-1);
        opacity: 1;
      }
      33% {
        transform: translate(-80%, -50%) scaleX(-1);
        opacity: 1;
      }
      66% {
        transform: translate(-50%, -50%) scaleX(-1);
        opacity: 1;
      }
      100% {
        transform: translate(-80%, -50%) scaleX(-1);
        opacity: 0;
      }
    }
  `,
}

export default styles
