/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import _get from 'lodash/get'
import _isNumber from 'lodash/isNumber'
import { useAppDispatch, useAppSelector } from 'store'
import { CUSTOM_EVENTS, NFT_TYPE, NFT_STATUS } from 'types/common'
import ShoeDetail from 'components/shoe-detail/ShoeDetail'
import { showToast } from 'store/reducers/common'
import { fetchBagShoeItem } from 'store/reducers/bag'
import SellModal from 'components/sell-modal/SellModal'
import RevokeModal from 'components/revoke-modal/RevokeModal'
import LevelUpModal from 'components/levelup-modal/LevelUpModal'
import AddPointModal from 'components/add-point-modal/AddPointModal'
import RepairModal from 'components/repair-modal/RepairModal'
import styles from '../market/ShoeDetailMarket.styles'

const ShoeDetailMarket = () => {
  const dispatch = useAppDispatch()
  const { shoeId } = useParams()
  const shoeDetail = useAppSelector((state) => state.bag.shoeDetail)
  const [isOpenConfirmSell, setOpenConfirmSell] = useState(false)
  const [isOpenConfirmRevoke, setOpenConfirmRevoke] = useState(false)
  const [isLevelUpOpen, setIsLevelUpOpen] = useState(false)
  const [isAddPointOpen, setIsAddPointOpen] = useState(false)
  const [isRepairOpen, setIsRepairOpen] = useState(false)

  const shoeStatus = shoeDetail?.status
  const isRunning = shoeDetail?.isRunning
  const shoeLoaded = shoeDetail?.apiStatus

  const navigate = useNavigate()
  const onSellSuccess = () => {
    dispatch(
      showToast({
        title: 'Listing successfully!',
      }),
    )
    setOpenConfirmSell(false)
    handleRefreshData()
  }

  const onRevokeSuccess = () => {
    dispatch(
      showToast({
        title: 'Revoke Complete!',
      }),
    )
    setOpenConfirmRevoke(false)
    handleRefreshData()
  }

  const onLevelUpSuccess = () => {
    dispatch(
      showToast({
        title: 'Upgrade Complete!',
      }),
    )
  }

  const onAddPointSuccess = () => {
    dispatch(
      showToast({
        title: 'Add Point Complete!',
      }),
    )
    handleRefreshData()
  }

  const onRepairSuccess = () => {
    dispatch(
      showToast({
        title: 'Repair Complete!',
      }),
    )
    handleRefreshData()
  }

  useEffect(() => {
    document.addEventListener(CUSTOM_EVENTS.REFRESH_CLICKED, handleRefreshData)
    handleRefreshData()

    return () => {
      document.removeEventListener(
        CUSTOM_EVENTS.REFRESH_CLICKED,
        handleRefreshData,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoeId])

  const handleRefreshData = () => {
    if (shoeId) {
      dispatch(
        fetchBagShoeItem({
          shoeId: +shoeId,
        }),
      )
    }
  }

  const handleShowCooldownTime = (cooldownTime: number) => {
    const mintHour = Math.ceil(
      (new Date(cooldownTime).getTime() - Date.now()) / 3600000,
    )

    if (mintHour > 0) {
      return dispatch(
        showToast({
          title: `This item is cooling down. You need to wait ${mintHour} ${
            mintHour === 1 ? 'hour' : 'hours'
          } for next action`,
        }),
      )
    }

    return dispatch(
      showToast({
        title: 'This item is cooling down. You need to wait for next action',
      }),
    )
  }

  const showShoeStatusAlert = () => {
    if (shoeStatus === NFT_STATUS.SELLING) {
      return dispatch(
        showToast({
          title: 'The action is not available in a selling item',
        }),
      )
    }

    if (shoeStatus === NFT_STATUS.LEVELING) {
      return dispatch(
        showToast({
          title: 'This action is not possible during level up',
        }),
      )
    }
    if (shoeStatus === NFT_STATUS.LOCKED) {
      return dispatch(
        showToast({
          title: 'The action is not available in this item',
        }),
      )
    }

    if (shoeStatus === NFT_STATUS.COOLDOWN) {
      const cooldownTime = _get(shoeDetail, 'cooldownTime')

      if (cooldownTime) {
        return handleShowCooldownTime(cooldownTime)
      }
    }

    if (isRunning) {
      return dispatch(
        showToast({
          title: 'This shoe is being used for running',
        }),
      )
    }

    // if (!walletOnchain?.address || walletOnchain?.address !== '') {
    //   return dispatch(
    //     showToast({
    //     title: 'Wallet is not exist',
    //   }))
    // }
  }

  const onLevelupFooterClick = () => {
    if (!shoeLoaded) {
      return
    }
    const levelValue = Number(_get(shoeDetail, 'level'))

    if (levelValue >= 30) {
      return dispatch(
        showToast({
          title: 'Maximum level reached',
        }),
      )
    }
    if (
      (shoeStatus === NFT_STATUS.ACTIVE ||
        shoeStatus === NFT_STATUS.LEVELING) &&
      !isRunning
    ) {
      // setIsLevelUpOpen(true);
      // eslint-disable-next-line no-console
      setIsLevelUpOpen(true)
    } else {
      showShoeStatusAlert()
    }
  }

  const onRepairFooterClick = () => {
    if (!shoeLoaded) {
      return
    }
    if (shoeStatus === NFT_STATUS.ACTIVE && !isRunning) {
      if (shoeDetail && shoeDetail.durable === 100) {
        return dispatch(
          showToast({
            title: 'Nothing to repair',
          }),
        )
      }
      setIsRepairOpen(true)
    } else {
      showShoeStatusAlert()
    }
  }

  const onSellFooterClick = () => {
    if (!shoeLoaded) {
      return
    }
    if (
      shoeDetail &&
      !isRunning &&
      (shoeStatus === NFT_STATUS.ACTIVE || shoeStatus === NFT_STATUS.SELLING)
    ) {
      if (_isNumber(shoeDetail.durable) && shoeDetail.durable < 100) {
        return dispatch(
          showToast({
            title: 'Not enough Durability',
            message: 'Please repair to full Durability before trading',
          }),
        )
      } else if (shoeDetail?.lifetime && shoeDetail?.lifetime <= 500) {
        return dispatch(
          showToast({
            title: 'Not enough Lifetime',
          }),
        )
      } else {
        if (shoeDetail?.status === NFT_STATUS.SELLING) {
          setOpenConfirmRevoke(true)
        } else {
          setOpenConfirmSell(true)
        }
      }
    } else {
      showShoeStatusAlert()
    }
  }

  const onMintFooterClick = () => {
    if (!shoeLoaded) {
      return
    }
    if (shoeStatus === NFT_STATUS.ACTIVE && !isRunning) {
      const cooldownTime = _get(shoeDetail, 'cooldownTime')

      if (cooldownTime) {
        const mintHour = Math.ceil(
          (new Date(cooldownTime).getTime() - Date.now()) / 3600000,
        )

        if (mintHour > 0) {
          return dispatch(
            showToast({
              title: `This item is cooling down. You need to wait ${mintHour} ${
                mintHour === 1 ? 'hour' : 'hours'
              } for next action`,
            }),
          )
        }
      }

      const levelValue = Number(_get(shoeDetail, 'level'))
      const mintValue = Number(_get(shoeDetail, 'mint'))

      if (levelValue < 5) {
        return dispatch(
          showToast({
            title: 'You need to reach level 5 to create new shoe',
          }),
        )
      }

      if (mintValue >= 7) {
        return dispatch(
          showToast({
            title: 'You can only mint 7 times for a shoe',
          }),
        )
      }
      navigate(`/bag/shoe/${shoeDetail?.shoeId}/mint`)
    } else {
      showShoeStatusAlert()
    }
  }

  const onTransferFooterClick = () => {
    if (!shoeLoaded) {
      return
    }

    if (
      shoeStatus === NFT_STATUS.ACTIVE
      // && walletOnchain?.address !== ''
    ) {
      // eslint-disable-next-line no-console
      // console.log('onTransferFooterClic')
      // navigation.navigate('TransferToken', {
      //   direction: DIRECTIONS.WITHDRAW,
      // });
    } else {
      showShoeStatusAlert()
    }
  }

  const onAddPointClick = () => {
    if (!shoeLoaded) {
      return
    }

    if (shoeStatus === NFT_STATUS.ACTIVE) {
      setIsAddPointOpen(true)
    } else {
      showShoeStatusAlert()
    }
  }

  return (
    <div css={styles.wrapper}>
      {!!shoeDetail?.shoeId && (
        <ShoeDetail
          shoeDetail={shoeDetail}
          onSellMenuClick={onSellFooterClick}
          onLevelupFooterClick={onLevelupFooterClick}
          onRepairFooterClick={onRepairFooterClick}
          onMintFooterClick={onMintFooterClick}
          onTransferFooterClick={onTransferFooterClick}
          onAddPointClick={onAddPointClick}
        />
      )}

      <SellModal
        itemDetail={shoeDetail}
        isOpen={isOpenConfirmSell}
        type={NFT_TYPE.SHOE}
        handleClose={() => setOpenConfirmSell(false)}
        onSellSuccess={onSellSuccess}
      />

      <RevokeModal
        itemDetail={shoeDetail}
        isOpen={isOpenConfirmRevoke}
        type={NFT_TYPE.SHOE}
        handleClose={() => setOpenConfirmRevoke(false)}
        onRevokeSuccess={onRevokeSuccess}
      />

      {(shoeStatus === NFT_STATUS.ACTIVE ||
        shoeStatus === NFT_STATUS.LEVELING) && (
        <LevelUpModal
          onLevelUpSuccess={onLevelUpSuccess}
          shoeDetailData={shoeDetail}
          isOpen={isLevelUpOpen}
          setOpen={setIsLevelUpOpen}
          loadDetail={handleRefreshData}
        />
      )}
      {shoeStatus === NFT_STATUS.ACTIVE && (
        <AddPointModal
          onSuccess={onAddPointSuccess}
          shoeDetail={shoeDetail}
          isOpen={isAddPointOpen}
          handleClose={() => setIsAddPointOpen(false)}
        />
      )}

      {shoeStatus === NFT_STATUS.ACTIVE && (
        <RepairModal
          onSuccess={onRepairSuccess}
          shoeDetail={shoeDetail}
          isOpen={isRepairOpen}
          handleClose={() => setIsRepairOpen(false)}
        />
      )}
    </div>
  )
}

export default ShoeDetailMarket
