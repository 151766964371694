import { css } from '@emotion/react'
import { baseColors, mobileWidth } from 'theme'
const styles = {
  wrapper: css`
    background-color: ${baseColors.cardBg};
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(95vw - 48px);
    border-radius: 16px;

    max-height: 90vh;
    overflow: auto;

    @media only screen and (min-width: ${mobileWidth}px) {
      width: 500px;
    }
  `,

  bgWrapper: css`
    width: calc(95vw - 48px);
    height: calc((95vw - 48px) * 0.6615);

    @media only screen and (min-width: ${mobileWidth}px) {
      width: 500px;
      height: calc(500px * 0.6615);
    }
  `,
  bgWrapperContent: css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  `,
  shoeImg: css`
    width: 50%;
    margin-bottom: -30px;
  `,
  shoeId: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
  `,

  modalTitle: css`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${baseColors.neutralColor1};
  `,

  actions: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 12px);
    max-width: 400px;
  `,
  cancelBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  confirmBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  shoeInfoWrapper: css`
    width: 90%;
  `,
  avaiPointWrapper: css({
    padding: 24,
    marginBottom: 0,
    backgroundColor: baseColors.appBg,
    borderRadius: 16,
    textAlign: 'center',
    margin: '16px 0',
  }),
  currentPoint: css`
    font-weight: 700;
    font-size: 48px;
    line-height: 52px;

    color: ${baseColors.primaryColor4};
    margin-bottom: 8px;
  `,
  addPointWrapper: css({
    padding: '8px 24px',
    backgroundColor: baseColors.appBg,
    borderRadius: 16,
  }),
  attributeIcon: css({
    width: 24,
    height: 24,
    borderRadius: 16,
    marginRight: 16,
  }),
  btnDisable: css({
    opacity: 0.6,
  }),
  pointAction: css`
    height: 30px;
    min-width: 30px;
    width: 30px;
    padding: 3px;
    img {
      width: 24px;
      height: 24px;
    }
  `,
  finalPoint: css({
    margin: 8,
    width: 40,
    textAlign: 'center',
  }),
  attrRow: css`
    margin: 12px 0;
  `,
}

export default styles
